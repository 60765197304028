import React, { useState } from "react"
import PropTypes from "prop-types"
import { ParallaxProvider } from "react-scroll-parallax"
import SwiperCore, { Lazy, Autoplay, EffectCoverflow, Mousewheel } from "swiper"
import Header from "../components/header"
import Footer from "./footer"
import SectionSearch from "../components/section-search"
import tos from "../data/tos.json"
import policy from "../data/privacy.json"

SwiperCore.use([Lazy, Autoplay, EffectCoverflow, Mousewheel])

const Layout = ({ children, style, className = "", showFooter = true }) => {
  const [keyword, setKeyword] = useState("")
  const [showSearchBar, setShowSearchBar] = useState(false)

  const handleSearchShow = () => {
    setShowSearchBar(true)
  }

  const handleSearch = e => {
    setKeyword(e.target.value)
  }

  const handleClear = () => {
    setKeyword("")
    setShowSearchBar(false)
  }

  const tosData = []
  const policyData = []

  tos.forEach(element => {
    tosData.push(element)
  })

  policy.forEach(element => {
    policyData.push(element)
  })

  const dataSearch = tosData.concat(policyData)

  const dynamicSearch = () => {
    const filters = dataSearch.filter(names =>
      names.name.toLowerCase().includes(keyword.toLowerCase())
    )
    return filters
  }

  return (
    <>
      <ParallaxProvider>
        <Header
          keyword={keyword}
          handleSearch={handleSearch}
          handleSearchShow={handleSearchShow}
          handleClear={handleClear}
          showSearchBar={showSearchBar}
        />
        <div className="body-wrapper">
          <div className="row">
            <main id="main" className={`${className}`} style={{ ...style }}>
              {keyword !== "" ? (
                <SectionSearch content={dynamicSearch()} keyword={keyword} />
              ) : (
                <>{children}</>
              )}
              {showFooter ? <Footer /> : ""}
            </main>
          </div>
        </div>
      </ParallaxProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
