import React from "react"
import { Link } from "gatsby"
import MainBtn from "../../components/button/mainBtn"
import modalImg from "../../assets/images/illustration-signup.svg"
import closeIcon from "../../assets/images/close-icon.svg"

const SignupModal = ({
  showModal,
  handleSubmit,
  handleEmailChange,
  errorMessage,
  access,
  success,
  inputValue,
}) => {
  return (
    <div>
      <div
        className={`overlay-mask ${access || success ? "is-active" : ""}`}
        onClick={access ? showModal : ""}
        role="presentation"
      ></div>
      <div className="modal-holder">
        <div className="newsletter-modal">
          <img
            src={closeIcon}
            className="close-icon"
            alt="close icon"
            onClick={showModal}
            role="presentation"
          />
          <img src={modalImg} className="modal-img" alt="modal illustration" />
          <div className="form-holder">
            <form onSubmit={handleSubmit} className="get-notified">
              <h3 className="page-title">
                Sign up now and bee the first to get Servbees exclusive biz
                tips, trends to watch out for, must-grab deals in the community,
                app updates, and more.
              </h3>
              <div className="subscribe-form">
                <div className="input-holder">
                  <input
                    type="email"
                    name="email"
                    required
                    className={`contact-input ${
                      errorMessage ? "error-field" : ""
                    }`}
                    onChange={handleEmailChange}
                    value={inputValue}
                  />
                  <span className="placeholder">Email</span>
                </div>
                <div className="signup-btn">
                  <MainBtn text="Sign up" type="submit" />
                </div>
              </div>
              {errorMessage ? <p className="error-text">{errorMessage}</p> : ""}
            </form>
          </div>
          <p className="modal-sub">
            We care about protecting your data. Here’s our
            <Link to="/privacy-policy" className="modal-link">
              <span className="privacy-text"> Privacy Policy.</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SignupModal
