import React from "react"
import closeIcon from "../../assets/images/close-icon.svg"

const SignupModal = ({
  handleSubmit,
  access,
  success,
  closeSuccess,
  modalImg,
  mainText,
  subText,
}) => {
  return (
    <div>
      <div className="modal-holder">
        <div className="newsletter-modal">
          <img
            src={closeIcon}
            className="close-icon"
            alt="close icon"
            onClick={closeSuccess}
            role="presentation"
          />
          <img src={modalImg} className="modal-img" alt="modal illustration" />
          <div className="form-holder">
            <form onSubmit={handleSubmit} className="get-notified">
              <h3 className="page-title">{mainText}</h3>
              <p className="modal-text">{subText}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupModal
