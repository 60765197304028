import React, { useState, useEffect } from "react"
import ReactHtmlParser from "react-html-parser"
import NoResults from "../assets/images/tos/no-results.svg"

const SectionSearch = props => {
  const [show, setShow] = useState("")

  const content = props.content
  const keyword = props.keyword
  const loc = props.location

  useEffect(() => {
    setShow("")
  }, [])

  return (
    <div className="search-results-wrapper">
      <div className="container">
        {content.length !== 0 ? (
          <>
            <div className="text-holder">
              {loc && (
                <h3>
                  {loc === "/terms"
                    ? "Terms of Use"
                    : "/privacy"
                    ? "Privacy Policy"
                    : "Frequently Asked Questions"}
                </h3>
              )}
              <p>
                Showing {content.length}&nbsp;
                {content.length === 1 ? "result" : "results"}
              </p>
            </div>
            {loc !== "/faq" &&
              content.map(tos => {
                return (
                  <div className="search-holder">
                    <p id={tos.href} className="title">
                      {tos.name}
                    </p>
                    <div
                      className="content"
                      style={{
                        maxHeight: show === tos.href ? "unset" : "55px",
                      }}
                    >
                      {tos.content.map(sub => {
                        return (
                          <>
                            <p className="sub">{ReactHtmlParser(sub.text)}</p>
                            <div className="subtext-holder">
                              {sub.textSub ? (
                                <>
                                  {sub.textSub.map(subt => (
                                    <p>{ReactHtmlParser(subt)}</p>
                                  ))}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        )
                      })}
                    </div>
                    {show === tos.href ? (
                      <span
                        role="presentation"
                        onClick={() => setShow("")}
                        className="read-more"
                      >
                        Read less
                      </span>
                    ) : (
                      <span
                        role="presentation"
                        onClick={() => setShow(tos.href)}
                        className="read-more"
                      >
                        Read more
                      </span>
                    )}
                  </div>
                )
              })}
            {loc === "/faq" &&
              content.map(faq => {
                return (
                  <div className="search-holder">
                    <p id={faq.slug} className="title">
                      {faq.name}
                    </p>
                    <div
                      className="content"
                      style={{
                        maxHeight: show === faq.slug ? "unset" : "55px",
                      }}
                    >
                      {faq.subsection.map(sub => {
                        return (
                          <>
                            <p className="sub">{ReactHtmlParser(sub.title)}</p>
                            <div className="subtext-holder">
                              <p>{ReactHtmlParser(sub.text)}</p>
                            </div>
                          </>
                        )
                      })}
                    </div>
                    {show === faq.slug ? (
                      <span
                        role="presentation"
                        onClick={() => setShow("")}
                        className="read-more"
                      >
                        Read less
                      </span>
                    ) : (
                      <span
                        role="presentation"
                        onClick={() => setShow(faq.slug)}
                        className="read-more"
                      >
                        Read more
                      </span>
                    )}
                  </div>
                )
              })}
          </>
        ) : (
          <div className="no-results">
            <div className="img-holder">
              <img src={NoResults} alt="" />
            </div>
            <div className="text-holder">
              <h4>
                Your search for "{keyword}" did not match any post. Try another
                search?
              </h4>
              <p>
                Check if the spelling is correct and try using different
                keywords
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SectionSearch
