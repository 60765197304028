import React from "react"

const MainBtn = ({ text, type, bgcolor, color }) => {
  return (
    // <div>
    <button
      className="btn"
      type={type}
      style={{ backgroundColor: `${bgcolor}`, color: `${color}` }}
    >
      {text}
    </button>
    // </div>
  )
}

export default MainBtn
